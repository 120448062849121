// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-me-tsx": () => import("./../../../src/pages/AboutMe.tsx" /* webpackChunkName: "component---src-pages-about-me-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/Education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

